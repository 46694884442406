<!-- 设备详情 -->
<template>
  <div>
    <div v-if="isadr">
      <pull-refresh
        :refreshing="isRefreshing"
        :on-refresh="onRefresh"
        pull-height="100vh"
      >
        <div class="detail">
          <div class="detail-device">
            <h3>{{ info.location }}</h3>
            <p class="addr">
              <i class="iconfont t-icon-dingwei-1"></i
              ><span>{{ info.address_detail }}</span>
            </p>
            <p class="fault" @click="to_fault()">
              <i class="iconfont t-icon-zhuyi"></i>故障反馈
            </p>
            <div class="mile">
              {{ info.distance }}
              <i class="iconfont t-icon-a-dingweijiantou"></i>
            </div>
          </div>
          <div class="detail-port">
            <h3>充电端口</h3>
            <ul class="tag-list d-flex">
              <li v-for="(item, index) in tag" :key="index">
                <span class="tag" :style="{ background: item.color }"></span
                >{{ item.txt }}
              </li>
            </ul>

            <ul class="port-list">
              <li v-for="(item, index) in port" :key="index" @click="tap(item)">
                <i
                  class="iconfont icon-chongdianzhuang"
                  :style="{
                    color:
                      item.status == 0
                        ? '#01BC84'
                        : item.status == 1
                        ? '#D6D6D6'
                        : '#EB0202',
                  }"
                ></i>
                <p class="num">{{ item.num }}</p>
              </li>
            </ul>

            <div class="tip">
              计费方式<!--<i class="iconfont t-icon-jisuanfangshi"></i>-->
            </div>
            <div class="hour">
              <p class="hour-sub">按时间计算：</p>
              <p class="hour-content">1元/{{ unit }}小时</p>
            </div>
          </div>
        </div>
      </pull-refresh>
    </div>
    <div class="no-list" v-else>
      <img
        class="no-img"
        src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/res/images/202106/25/chongdian/E-dw%403x.png"
      />
      <p class="no-txt">哎呦～无法显示当前位置哦</p>
      <button class="no-btn" @click="get_wx_location">授权当前位置</button>
    </div>
    <!--充电支付-->
    <van-action-sheet v-model="show" title="充电支付">
      <div class="pay">
        <div class="pay-box">
          <div class="pay-title">
            充电端口：<span class="pay-port">{{ port_id }}号</span>
          </div>
        </div>
        <div class="pay-box">
          <div class="pay-title">
            充电金额：<span class="pay-hour">1元/{{ unit }}小时</span>
          </div>
          <ul class="money-list">
            <li
              :class="item == money ? 'active' : ''"
              v-for="(item, index) in money_list"
              :key="index"
              @click="change_money(item)"
            >
              {{ item }}元
            </li>
          </ul>
          <div class="pay-title">充电方式：</div>
          <ul class="way-list">
            <van-radio-group v-model="radio">
              <li>
                <p class="way-title">
                  <i class="iconfont t-icon-weixin"></i>微信支付
                </p>
                <van-radio name="1">
                  <template #icon="">
                    <img
                      class="img-icon"
                      :src="radio == 1 ? activeIcon : inactiveIcon"
                    />
                  </template>
                </van-radio>
              </li>
              <li>
                <p class="way-title">
                  <i class="iconfont t-icon-qianbao"></i>余额支付<span
                    class="way-sun"
                    >（余额：{{ balance > 0 ? "￥" + balance : 0 }}）</span
                  >
                </p>
                <van-radio name="2" :disabled="balance == 0">
                  <template #icon="">
                    <img
                      class="img-icon"
                      :src="radio == 2 ? activeIcon : inactiveIcon"
                    />
                  </template>
                </van-radio>
              </li>
            </van-radio-group>
          </ul>
          <div class="tip">
            <i class="iconfont t-icon-jisuanfangshi"></i
            >设备若发生中断，按实际充电时间收费
          </div>
        </div>

        <div class="pay-all d-flex">
          <div>
            <p>
              实付金额：<span class="pay-total"><i>￥</i>{{ money }}</span>
            </p>
            <p>
              充电时长：<span>{{ hours }}小时</span>
            </p>
          </div>
          <button class="pay-button" @click="pay">去支付</button>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { getData, postData } from "@/api/user";
import { _debounce } from "@/libs/util";
import wx from "weixin-js-sdk";
import pullRefresh from "@/components/pullRefresh.vue";

export default {
  data() {
    return {
      isadr: true,
      id: "",
      info: {},
      balance: 0,
      tag: [
        {
          color: "#01BC84",
          txt: "空闲",
        },
        {
          color: "#EB0202",
          txt: "故障",
        },
        {
          color: "#D6D6D6",
          txt: "占用",
        },
      ],
      port: [],
      show: false,
      port_id: 0,
      activeIcon:
        "https://ebaina.oss-cn-hangzhou.aliyuncs.com/res/images/202106/25/chongdian/2021625-17956.png",
      inactiveIcon:
        "https://ebaina.oss-cn-hangzhou.aliyuncs.com/res/images/202106/25/chongdian/2021625-17941.png",
      money_list: [1, 2, 3, 4],
      money: 1, // 总共支付多少钱
      unit: 4, // 每小时多少元
      hours: 0, // 充电时长,
      radio: 1,
      latitude: "",
      longitude: "",
      isRefreshing: false,
      pay_flag: false, // 防止多次触发
    };
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f7f7f7");
  },
  components: {
    pullRefresh,
  },
  created() {
    this.id = this.$route.query.id;
    this.get_wx_location();
  },
  methods: {
    get_wx_location() {
      let that = this;
      let url = "";
      let ua = navigator.userAgent.toLowerCase();
      if (/iphone|ipad|ipod/.test(ua)) {
        url = location.href.split("#")[0];
      } else if (/android/.test(ua)) {
        url = location.href;
      }

      getData({
        url: "api/get_js_config",
        data: {
          url,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          wx.config({
            debug: false,
            appId: data.appId + "",
            timestamp: data.timestamp,
            nonceStr: data.nonceStr + "",
            signature: data.signature + "",
            jsApiList: ["checkJsApi", "getLocation"], // 必填,需要使用的JS接口列表
          });
          wx.error(function (res) {
            that.isadr = false;
            alert("出错了：" + res.errMsg);
          });
          wx.ready(function () {
            wx.checkJsApi({
              // 判断当前客户端版本是否支持指定JS接口
              jsApiList: ["getLocation"],
              success: function (res) {
                if (res.checkResult.getLocation === true) {
                  wx.getLocation({
                    type: "gcj02", // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                    success: function (res) {
                      that.latitude = res.latitude;
                      that.longitude = res.longitude;
                      that.get_balance();
                      that.get_info();
                      that.isadr = true;
                    },
                    fail: function () {
                      that.isadr = false;
                    },
                    cancel: function () {
                      that.isadr = false;
                    },
                  });
                } else {
                  that.isadr = false;
                  alert("抱歉，当前客户端版本不支持获取地理位置");
                }
              },
              fail: function (res) {
                that.isadr = false;
                // 检测getNetworkType该功能失败时处理
                alert("检测该功能失败" + res);
              },
            });
          });
        } else {
          that.isadr = false;
        }
      });
    },
    // 账户余额
    get_balance() {
      getData({
        url: "api/charging/user/user_balance",
        data: {},
      }).then((res) => {
        let data = res.data.data;
        if (res.data.code === 200) {
          this.balance = data.data || 0;
        } else if (res.data.code === 422) {
          this.$toast(res.data.data.msg);
        }
      });
    },
    get_info() {
      getData({
        url: `api/charging/charger/map_charger_info`,
        data: {
          id: this.id,
          latitude: this.latitude,
          longitude: this.longitude,
        },
      }).then((res) => {
        var data = res.data.data.data;
        if (res.data.code === 200) {
          this.info = data.charger_info;
          // this.info.mile = this.hasDot(data.charger_info.distance);
          this.port = data.charger_port; // 0空闲，1占用，2故障
          this.unit =
            this.info.village && this.info.village.hours
              ? this.info.village.hours
              : 4;
        } else if (res.data.code === 422) {
          this.$toast(res.data.data.msg);
        }
      });
    },
    refresh() {
      if (this.longitude && this.latitude) {
        this.get_info();
        this.get_balance();
      } else {
        this.get_wx_location();
      }
    },
    onRefresh() {
      this.isRefreshing = true;
      setTimeout(() => {
        this.refresh();
        this.isRefreshing = false;
      }, 2000);
    },
    hasDot(num) {
      if (!isNaN(num)) {
        return (num + "").indexOf(".") != -1 ? num.toFixed(2) : num;
      }
    },
    to_fault() {
      this.$router.push({
        name: "fault",
        query: {
          charger_id: this.info.id,
          port_id: this.port_id,
          type: "detail",
        },
      });
    },
    tap(item) {
      if (item.status == 0) {
        this.show = true;
        this.hours = this.money * this.unit;
        this.radio = 1;
        this.port_id = item.num;
      } else if (item.status == 1) {
        this.$toast("此桩被占用！");
      } else {
        this.$toast("此桩故障！");
      }
    },
    change_money(item) {
      this.money = item;
      this.hours = item * this.unit;
    },
    pay: _debounce(function () {
      let that = this;
      if (this.radio == 1) {
        postData({
          url: "api/charging/consume/pay",
          data: {
            charger_id: this.info.id,
            port_id: this.port_id,
            money: this.money,
          },
        }).then((res) => {
          let pay_data = res.data;

          getData({
            url: "api/get_js_config",
            data: {
              url: window.location.href.split("#")[0],
            },
          }).then((res) => {
            let data = res.data.data;
            wx.config({
              debug: false,
              appId: data.appId + "",
              timestamp: data.timestamp,
              nonceStr: data.nonceStr + "",
              signature: data.signature + "",
              jsApiList: ["checkJsApi", "chooseWXPay"],
            });
            wx.error(function (res) {
              alert("出错了：" + res.errMsg); //这个地方的好处就是wx.config配置错误，会弹出窗口哪里错误，然后根据微信文档查询即可。
            });

            wx.ready(function () {
              wx.checkJsApi({
                // 判断当前客户端版本是否支持指定JS接口
                jsApiList: ["chooseWXPay"],
                success: function (res) {
                  if (res.checkResult.chooseWXPay === true) {
                    wx.chooseWXPay({
                      appId: pay_data.appId + "",
                      timestamp: pay_data.timestamp,
                      nonceStr: pay_data.nonceStr + "",
                      package: pay_data.package + "",
                      signType: pay_data.signType + "",
                      paySign: pay_data.paySign + "",
                      prepayId: pay_data.prepayId + "",
                      success: function () {
                        // that.$toast("支付成功 》》》" + that.info.id);

                        that.get_current_user_charging();
                      },
                      fail() {
                        that.$toast("支付失败");
                      },
                    });
                  } else {
                    alert("抱歉，当前客户端版本不支持");
                  }
                },
                fail: function (res) {
                  // 检测getNetworkType该功能失败时处理
                  alert("检测getNetworkType该功能失败" + res);
                },
              });
            });

            /* 处理失败验证 */
            wx.error(function (res) {
              // config 信息验证失败会执行error函数,如签名过期导致验证失败,具体错误信息可以打开config的debug模式查看,也可以在返回的res参数中查看,对于SPA可以在这里更新签名
              alert("配置验证失败: " + res.errMsg);
            });
          });

          if (res.data.code === 422) {
            this.$toast(res.data.data.msg);
          }
        });
      } else {
        this.balance_pay();
      }
    }),
    // 余额支付
    balance_pay: _debounce(function () {
      if (this.pay_flag) return;
      this.pay_flag = true;

      postData({
        url: "api/charging/consume/pay_by_balance",
        data: {
          charger_id: this.info.id,
          port_id: this.port_id,
          pay_money: this.money,
        },
      }).then((res) => {
        let data = res.data;
        console.log("pay_res", data);
        if (data.code === 200) {
          this.$toast(data.data.msg);
          this.get_current_user_charging();
        } else if (data.code === 422) {
          this.$toast(data.data.msg);
          this.pay_flag = false;
        } else {
          this.pay_flag = false;
        }
      });
    }),

    // 充值完成 跳转详情页面
    get_current_user_charging() {
      getData({
        url: `api/charging/index/current_user_charging`,
        data: {},
      }).then((res) => {
        if (res.data.code === 200) {
          let ids = res.data.data.consume_ids;
          // this.$router.replace({
          //   name: "power",
          //   query: {
          //     id: ids[ids.length - 1],
          //   },
          // });

          window.history.replaceState(
            null,
            null,
            `/power?id=${ids[ids.length - 1]}`
          );
          location.reload();
        }
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    var u = navigator.userAgent;

    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

    // XXX: 修复iOS版微信HTML5 History兼容性问题

    if (isiOS && to.path !== location.pathname) {
      // 此处不可使用location.replace

      location.assign(to.fullPath);
    } else {
      next();
    }
  },
};
</script>

<style lang="less">
.detail {
  padding: 15px 12px;
  h3 {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: #333;
    padding-right: 110px;
  }
  .detail-device {
    position: relative;
    margin-bottom: 12px;
    padding: 16px;
    background: #fff;
    border-radius: 5px;
    font-size: 14px;

    .addr {
      display: flex;
      align-items: flex-start;
      margin-bottom: 4px;
      font-size: 14px;
      line-height: 20px;
      color: #999;
      padding-right: 100px;
      .iconfont {
        height: 12px;
        width: 12px;
        background-repeat: no-repeat;
        margin-right: 4px;
        margin-top: 3px;
      }
      span {
        flex: 1;
      }
    }
    .fault {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 6px;
      text-align: right;
      font-size: 12px;
      font-weight: 600;
      line-height: 17px;
      color: #3dc58e;
      text-decoration: underline;
      .iconfont {
        height: 13px;
        width: 15px;
        background-repeat: normal;
        margin-right: 4px;
        background-repeat: no-repeat;
      }
    }
    .mile {
      display: flex;
      align-items: center;
      position: absolute;
      right: 17px;
      top: 19px;
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      color: #333;
      .iconfont {
        height: 17px;
        width: 17px;
        background-repeat: no-repeat;
        margin-left: 8px;
      }
    }
  }
  .detail-port {
    padding: 16px 16px 30px;
    background: #ffffff;
    border-radius: 5px;
  }
  .tag-list {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 30px;
    li {
      margin-right: 14px;
    }
    .tag {
      display: inline-block;
      margin-right: 4px;
      width: 10px;
      height: 10px;
      margin-right: 4px;
      opacity: 1;
      border-radius: 2px;
      background: #3dc58e;
    }
  }
  .port-list {
    margin-bottom: 4px;
    display: flex;
    flex-flow: row wrap;
    li {
      flex: 0 0 20%;
      text-align: center;
      margin-bottom: 32px;
    }
    .img {
      height: 46px;
      width: 46px;
      margin-bottom: 9px;
    }
    .iconfont {
      font-size: 46px;
      height: 46px;
      width: 46px;
      margin-bottom: 9px;
    }
    .num {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      color: #333;
    }
  }
  .tip {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 20px;
    color: #999;
    .iconfont {
      height: 15px;
      width: 15px;
      color: #c4c4c4;
      margin-left: 5px;
      background-repeat: no-repeat;
    }
  }
  .hour {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .hour-sub {
    font-size: 14px;
    color: #666;
  }
  .hour-content {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: #ffae15;
  }
}
.pay {
  .img-icon,
  .van-checkbox__icon {
    height: 20px;
    width: 20px;
  }
}
</style>